import React from 'react';
import InterShipCertificate from '../../images/certificates/internshipCertificate.png';
import SummerInterShip from '../../images/certificates/completionCertificate.png';

const Certificates = () => {
    return (
        <>
            <section className="CertificateSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 class="YellowHeading">Our Certificate</h2>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-md-6 col-12">
                            <div className="CertiDiv">
                                <img src={InterShipCertificate} alt="Intership Certificate" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="CertiDiv">
                                <img src={SummerInterShip} alt="Summer Intership Certificate" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Certificates