import React, { forwardRef, useState } from "react";
import CurriculumForm from "./CurriculumForm";

import Courses2 from "../../images/Courses(2).png";
import Courses3 from "../../images/Courses(3).png";
import Courses4 from "../../images/Courses(4).png";
import java from "../../images/course/java.png";
import generativeAI from "../../images/course/artificial-intelligence.png";
import dataAnalytics from "../../images/course/DATA ANALYTICS.png";
import linux from "../../images/course/linux basic and command.png";
import python from "../../images/course/python.jpg";
import { Link } from "react-router-dom";


const coursesData = [
  {
    id: 1,
    image: Courses2,
    title: "DevOps & Cloud Computing",
    duration: "45 Days (6 Weeks)",
    details:
      "Linux, AWS, Azure, Jenkins, Ansible, Terraform, Docker, Kubernetes",
  },
  {
    id: 2,
    image: Courses3,
    title: "Data Science with ML & AI",
    duration: "45 Days (6 Weeks)",
    details:
      "Python, Databases, Machine Learning, Deep Learning, Computer Vision, NLP",
  },
  {
    id: 3,
    image: Courses4,
    title: "Full Stack Web Development",
    duration: "45 Days (6 Weeks)",
    details: "HTML, CSS, JavaScript, ReactJS, NodeJS, ExpressJS, MongoDB",
  },
  {
    id: 4,
    image: dataAnalytics,
    title: "Data Analytics",
    duration: "45 Days (6 Weeks)",
    details:
      "Excel, SQL, Data Visualization, Power BI, Tableau, Dashboards, ETL",
  },
  {
    id: 8,
    image: generativeAI,
    title: "Generative AI",
    duration: "45 Days (6 Weeks)",
    details:
      "AI fundamentals, Neural networks, Large Language Models (LLMs), GANs",
  },
  {
    id: 9,
    image: java,
    title: "Core Java",
    duration: "45 Days (6 Weeks)",
    details:
      "Java basics, OOP concepts, Collections framework, JDBC, Multithreading, Design patterns",
  },
  {
    id: 5,
    image: linux,
    title: "Linux",
    duration: "15 Days",
    details:
      "Linux fundamentals, Shell scripting, System administration, Security",
  },
  {
    id: 6,
    image: python,
    title: "Python",
    duration: "15 Days",
    details: "Python basics, OOPs, File handling",
  },
  {
    id: 7,
    image: Courses4,
    title: "Frontend Development",
    duration: "15 Days",
    details:
      "HTML, CSS, JavaScript, Responsive Design,Web Design Best Practices",
  },
  
];

const Courses = forwardRef((props, ref) => {
  const [curriculum, setCurriculum] = useState({
    isOpen: false,
    courseId: null,
  });

  const openCurriculum = (courseId) => {
    setCurriculum({ isOpen: true, courseId });
  };

  return (
    <section ref={ref} className="CoursesSec" id="CourseID">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="Headings text-center">SUMMER TRAINING / INTERNSHIP PROGRAMS 2025</h2>
          </div>
          <div className="col-12">
            <div className="CoursesDiv">
              <ul>
                {coursesData.map((course) => (
                  <li key={course.id}>
                    <div className="icn">
                      <img src={course.image} alt="course" />
                      <div className="con">{course.title}</div>
                    </div>
                    <div className="conLI">
                      <ol>
                        <li>Duration - {course.duration}</li>
                        <li>{course.details}</li>
                      </ol>
                    </div>
                    <button
                      type="button"
                      onClick={() => openCurriculum(course.id)}
                      className="curriculumBTN"
                    >
                      Download Curriculum
                    </button>
                  </li>
                ))}
              </ul>
              <a style={{textDecoration:'none'}} href={'#register-section'}>
              <button className="YellowBTN mt-4 m-auto d-flex justify-content-center">
                Enroll Now
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {curriculum.isOpen && (
        <CurriculumForm
          closeCurriculum={() => setCurriculum({ isOpen: false, courseId: null })}
          courseId={curriculum.courseId}
        />
      )}
    </section>
  );
});

export default Courses;




// import React, { forwardRef, useState } from "react";
// import "./courseSlider.css";

// import Courses2 from "../../images/Courses(2).png";
// import Courses3 from "../../images/Courses(3).png";
// import Courses4 from "../../images/Courses(4).png";
// import java from "../../images/course/java.png";
// import generativeAI from "../../images/course/artificial-intelligence.png";
// import dataAnalytics from "../../images/course/DATA ANALYTICS.png";
// import linux from "../../images/course/linux basic and command.png";
// import python from "../../images/course/python.jpg";

// import CurriculumForm from "./CurriculumForm";

// const coursesData = [
//   {
//     id: 1,
//     image: Courses2,
//     title: "DevOps & Cloud Computing",
//     duration: "45 Days (6 Weeks)",
//     details:
//       "Linux, AWS, Azure, Jenkins, Ansible, Terraform, Docker, Kubernetes",
//   },
//   {
//     id: 2,
//     image: Courses3,
//     title: "Data Science with ML & AI",
//     duration: "45 days (6 Weeks)",
//     details:
//       "Python, Databases, Machine Learning, Deep Learning, Computer Vision, NLP",
//   },
//   {
//     id: 3,
//     image: Courses4,
//     title: "Full Stack Web Development",
//     duration: "45 days (6 Weeks)",
//     details: "HTML, CSS, JavaScript, ReactJS, NodeJS, ExpressJS, MongoDB",
//   },
//   {
//     id: 4,
//     image: dataAnalytics,
//     title: "Data Analytics",
//     duration: "45 days (6 Weeks)",
//     details:
//       "Excel, SQL, Data Visualization, Power BI, Tableau, Dashboards, ETL",
//   },
//   {
//     id: 5,
//     image: linux,
//     title: "Linux",
//     duration: "14 days",
//     details:
//       "Linux fundamentals, Shell scripting, System administration, Security",
//   },
//   {
//     id: 6,
//     image: python,
//     title: "Python",
//     duration: "14 days",
//     details: "Python basics, OOPs, File handling",
//   },
//   {
//     id: 7,
//     image: Courses4,
//     title: "Frontend Development",
//     duration: "14 days",
//     details:
//       "HTML, CSS, JavaScript, Responsive Design, React.js, UI/UX best practices",
//   },
//   {
//     id: 8,
//     image: generativeAI,
//     title: "Generative AI",
//     duration: "6 Weeks",
//     details:
//       "AI fundamentals, Neural networks, Large Language Models (LLMs), GANs",
//   },
//   {
//     id: 9,
//     image: java,
//     title: "Core Java",
//     duration: "6 Weeks",
//     details:
//       "Java basics, OOP concepts, Collections framework, JDBC, Multithreading, Design patterns",
//   },
// ];

// const Courses = forwardRef((props, ref) => {
//   const [curriculum, setCurriculum] = useState({
//     isOpen: false,
//     courseId: null,
//   });

//   const openCurriculum = (courseId) =>
//     setCurriculum({ isOpen: true, courseId });

//   return (
//     <section ref={ref} className="CoursesSec" id="CourseID">
//       <div className="container">
//         <h2 className="Headings text-center">Our Summer Internship Courses</h2>
//         <div className="row">
//         <div className="CoursesDiv">
//           <ul>
//           {coursesData.map((course) => (
//             <li>
//               <div className="icn">
//                 <img src={Courses2} alt="course" />
//                 <div className="con">DevOps & Cloud Computing</div>
//               </div>
//               <div className="conLI">
//                 <ol>
//                   <li>Duration - 45 days (6Weeks)</li>
//                   <li>
//                     Linux, AWS, Azure, Jenkins, Ansible and Terraform , Docker,
//                     Kubernetes
//                   </li>
//                 </ol>
//               </div>
//               <button
//                 type="button"
//                 onClick={() => {
//                   openCurriculum(1);
//                 }}
//                 className="curriculumBTN"
//               >
//                 Download Curriculum
//               </button>
//             </li>
//           ))}
//            </div>
//                            </ul>
//         </div>
//         <button className="YellowBTN mt-4 m-auto d-flex justify-content-center">
//           Enroll Now
//         </button>
//       </div>
//       {curriculum.isOpen && (
//         <CurriculumForm
//           closeCurriculum={() =>
//             setCurriculum({ isOpen: false, courseId: null })
//           }
//           courseId={curriculum.courseId}
//         />
//       )}
//     </section>
//   );
// });

// export default Courses;

// // import React, { forwardRef, useState } from "react";
// // // import Courses1 from "../../images/Courses(1).png";
// // import Courses1 from "../../images/syllabus/data-analytics.png";
// // import Courses2 from "../../images/Courses(2).png";
// // import Courses3 from "../../images/Courses(3).png";
// // import Courses4 from "../../images/Courses(4).png";
// // import CurriculumForm from "./CurriculumForm";
// // import EnrollForm from "./EnrollForm";

// // const Coureses = forwardRef((props, ref) => {
// //   const [curriculum, setCurriculum] = useState({
// //     isOpen: false,
// //     courseId: null,
// //   });

// //   const openCurriculum = (courseId) => {
// //     setCurriculum({
// //       isOpen: true,
// //       courseId: courseId,
// //     });
// //   };

// //   const [IsModelOpen, setIsModelOpen] = useState(false);

// //   const FormOpenBTN = () => {
// //     // setIsModelOpen(true);
// //     const paymentSec = document.getElementById("register-section");
// //     paymentSec.scrollIntoView({ block: "end" });
// //   };

// //   const closeModel = () => {
// //     setIsModelOpen(false);
// //   };

// //   return (
// //     <>
// //       <section ref={ref} className="CoursesSec" id="CourseID">
// //         <div className="container">
// //           <div className="row">
// //             <div className="col-12">
// //               <h2 className="Headings text-center">
// //                 Our Summer Internship Courses
// //               </h2>
// //             </div>
// //             <div className="col-12">
// //               <div className="CoursesDiv">
// //                 <ul>
// //                   <li>
// //                     <div className="icn">
// //                       <img src={Courses2} alt="course" />
// //                       <div className="con">DevOps & Cloud Computing</div>
// //                     </div>
// //                     <div className="conLI">
// //                       <ol>
// //                         <li>Duration - 45 days (6Weeks)</li>
// //                         <li>
// //                           Linux, AWS, Azure, Jenkins, Ansible and Terraform ,
// //                           Docker, Kubernetes
// //                         </li>
// //                       </ol>
// //                     </div>
// //                     <button
// //                       type="button"
// //                       onClick={() => {
// //                         openCurriculum(1);
// //                       }}
// //                       className="curriculumBTN"
// //                     >
// //                       Download Curriculum
// //                     </button>
// //                   </li>
// //                   <li>
// //                     <div className="icn">
// //                       <img src={Courses3} alt="course" />
// //                       <div className="con">Data Science with ML & AI</div>
// //                     </div>

// //                     <div className="conLI">
// //                       <ol>
// //                         <li>Duration - 45 days (6Weeks)</li>
// //                         <li>
// //                           Python, Databases, Machine Learning, Deep Learning,
// //                           Computer Vision, Natural Language Processing
// //                         </li>
// //                       </ol>
// //                     </div>
// //                     <button
// //                       type="button"
// //                       onClick={() => {
// //                         openCurriculum(2);
// //                       }}
// //                       className="curriculumBTN"
// //                     >
// //                       Download Curriculum
// //                     </button>
// //                   </li>
// //                   <li>
// //                     <div className="icn">
// //                       <img src={Courses4} alt="course" />
// //                       <div className="con">Full Stack Web Development</div>
// //                     </div>
// //                     <div className="conLI">
// //                       <ol>
// //                         <li>Duration - 45 days (6Weeks)</li>
// //                         <li>
// //                           HTML, CSS, JavaScript, ReactJS, NodeJS, ExpressJS,
// //                           MongoDB
// //                         </li>
// //                       </ol>
// //                     </div>
// //                     <button
// //                       type="button"
// //                       onClick={() => {
// //                         openCurriculum(3);
// //                       }}
// //                       className="curriculumBTN"
// //                     >
// //                       Download Curriculum
// //                     </button>
// //                   </li>
// //                   <li>
// //                     <div className="icn">
// //                       <img src={Courses1} alt="course" />
// //                       <div className="con">Data Analytics</div>
// //                     </div>

// //                     <div className="conLI">
// //                       <ol>
// //                         <li>Duration - 45 days (6Weeks)</li>
// //                         <li>
// //                         Excel, SQL, Data Visualization, Power BI, Tableau,
// //                         Creating Dashboards and ETL process
// //                         </li>
// //                       </ol>
// //                     </div>
// //                     <button
// //                       type="button"
// //                       onClick={() => {
// //                         openCurriculum(4);
// //                       }}
// //                       className="curriculumBTN"
// //                     >
// //                       Download Curriculum
// //                     </button>
// //                   </li>
// //                 </ul>
// //                 <button
// //                   class="YellowBTN mt-4 m-auto d-flex justify-content-center"
// //                   onClick={FormOpenBTN}
// //                 >
// //                   Enroll Now
// //                 </button>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //       {curriculum.isOpen && (
// //         <CurriculumForm
// //           closeCurriculum={() => {
// //             setCurriculum({ isOpen: false, courseId: null });
// //           }}
// //           courseId={curriculum.courseId}
// //         />
// //       )}
// //       {/* {IsModelOpen && <EnrollForm closeModel={closeModel} />} */}
// //     </>
// //   );
// // });

// // export default Coureses;
