import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import datasciencePDF from '../../images/curriculum-Data-Science.pdf'
import datasciencePDF from "../../images/syllabus/45 Days Module - Data Science with Machinde Learning.pdf";
// import fullstackPDF from '../../images/curriculum-Full-Stack-Development.pdf'
import fullstackPDF from "../../images/syllabus/45 Days Module - Full stack Web Developement - NEW.pdf";
import devopsPDF from "../../images/curriculum-Devops.pdf";
import devopsPDF1 from "../../images/30_Hours_Module_DevOPS.pdf";
import iotPDF from "../../images/Embedded-IoT.pdf";
import dataAnalytics from "../../images/syllabus/45 Days Module - Data Analytics.pdf";
import linux from "../../images/syllabus/2 Weeks - Linux Module.pdf";
import python from "../../images/syllabus/2 Weeks - Python Module.pdf";
import frontend from "../../images/syllabus/2 Weeks - Frontend Module.pdf";
import generativeAI from "../../images/syllabus/45 Days Module - Generative AI.pdf";
import java from "../../images/syllabus/45 Days Module - Core Java.pdf";

const courseMap = {
  1: { course: "devOps", pdf: devopsPDF },
  2: { course: "data science", pdf: datasciencePDF },
  3: { course: "full stack Development", pdf: fullstackPDF },
  4: { course: "data analytics", pdf: dataAnalytics },
  5: { course: "linux", pdf: linux },
  6: { course: "python", pdf: python },
  7: { course: "frontend Development", pdf: frontend },
  8: { course: "Generative AI", pdf: generativeAI },
  9: { course: "java", pdf: java },
};

const CurriculumForm = (props) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS;
const { course = "", pdf = null } = courseMap[props.courseId] || {};
  const getRedirectpath = (courseId) => {
    return pdf;
  };

  const [inpval, setinpval] = useState({
    fname: "",
    email: "",
    phone: "",
  });

  const setvalue = async (e) => {
    const { name, value } = e.target;

    setinpval(() => {
      return {
        ...inpval,
        [name]: value,
      };
    });
  };

  // console.log(url)
  const dataSubmit = async (e) => {
    e.preventDefault();
    const { fname, email, phone } = inpval;

    if (!fname || !email || !phone) {
      toast.error("Please Fill all fields");
    }

    try {
      const data = await fetch(`${BACKEND_URL}/curriculum/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName:fname,
          email,
          mobileNumber:phone,
          page: "summer-internship",
          pdfDownload:course
        }),
      });

      const res = await data.json();
      console.log(res);
      if (res.status === 201) {
        setinpval({
          fname: "",
          email: "",
          phone: "",
        });
        toast.success("Registration Successfully");
        // navigate(getRedirectpath(props.courseId))
        window.open(getRedirectpath(props.courseId), '_blank');
        // window.location.href = getRedirectpath(props.courseId);
        // console.log(getRedirectpath(props.courseId))
      }
      console.log(res.status);
      if (res.status === 409) {
        toast.error("This Email allready register");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`PopUpmodal CurriculumModel`} id="MainParentPopup">
        <div className="modalPopUpcontent">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Enter your details to download the program syllabus
            </h4>
            <button
              type="button"
              className="close close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeCurriculum}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="w3l-contacts-12 home_form">
              <div className="contacts12-main">
                <form className="main-input">
                  <input
                    type="text"
                    placeholder="Name"
                    name="fname"
                    value={inpval.fname}
                    onChange={setvalue}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={inpval.email}
                    onChange={setvalue}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Number"
                    pattern="[0-9][0-9]{9}" 
       maxlength="10"
                    name="phone"
                    value={inpval.phone}
                    onChange={setvalue}
                    required
                  />
                  <div>
                    {/* <NavLink onClick={dataSubmit} to={getRedirectpath(props.courseId)} className="YellowBTN">
                                            Download Now
                                        </NavLink> */}
                    <button
                      type="submit"
                      onClick={dataSubmit}
                      className="YellowBTN"
                    >
                      Download Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CurriculumForm;
