import { useState, useEffect } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import google from "../../images/google.png";

const reviews = [
  {
    name: "Neeta Rawat",
    text: `I completed a 6-month internship as a Full Stack Web Developer Intern at this company and had a fantastic experience. The knowledgeable and understanding instructors helped me gain valuable technical and practical skills. I worked on various mini and major projects, applying my knowledge to important tasks. This internship significantly enhanced both my technical and non-technical skills, boosting my confidence in professional settings. I'm grateful for the opportunity and the immense growth I experienced here.`,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjU-6yPajTNVuF76lGUJKMwNxlgWAXmv63NADXQvOeKC9q0vzSs=w40-h40-p-rp-mo-br100",
  },
  {
    name: "Milind Mehta",
    text: "I am thoroughly impressed with UPFAILERS PVT Ltd. The teachers are exceptional.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjVDHDGpEb3_zVpVl6tXCsaudbUwF9aoinFdnuesb4G2CxxgJ-3d=w66-h66-p-rp-mo-br100",
  },
  {
    name: "Riya Sharma",
    text: `I had a good experience at learning in upflairs. We had regular classes and practical implementation of machine learning and data science projects....
Faculty is best as educator and also teaches everything in beginner friendly manner.
We did a many projects and practically learnt the deployment.`,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjU0RSXvjFEw2k5nElsBhVZBLiI5nCROSHl5ggGZ9qkW_aQHT14=w66-h66-p-rp-mo-br100",
  },
  {
    name: "Jai vishun Singh",
    text: "Completed 15 days training as a Linux intern from Upflairs. Gained a lot of knowledge and skills.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjVRmDfNHRlfxD-P49K1rtuEcvbnbs94rxuXGtPNjwWlx3unDDM=w66-h66-p-rp-mo-br100",
  },
  {
    name: "Vikalp Jain SM",
    text: `I Completed a 45 days internship as a  Full Stack Web Development Internship at this company and had a fantastic experience. I worked on various mini and major projects, applying my knowledge to important tasks.`,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjV6L6tG8nsv-pSKToqwFQWChnWQU0VXZlX_whk15ACeW5_4lJ1Z=w40-h40-p-rp-mo-br100",
  },
  {
    name: "Bhargavi Gupta",
    text: "Nice learning experience. Co-operative staff and mentor. Doubt solving was done very well.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjURwAob1I4a6hvkyw7hxwZhhz_VlzmrXp5Q7eGl-_6Vj1Z5GAw=w66-h66-p-rp-mo-br100",
  },
 
 
];

export default function GoogleReviews() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3); // Default for Desktop

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1); // Mobile (1 Item)
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2); // Tablet (2 Items)
      } else {
        setSlidesToShow(3); // Desktop (3 Items)
      }
    };

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 2000); // Auto-slide every 2 seconds

    return () => clearInterval(autoSlide); // Cleanup on unmount
  }, [slidesToShow]);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % reviews.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className="container py-4">
      {/* Header Section */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center bg-light rounded-4 mb-3 p-4">
        <div className="text-center text-md-start">
          <h2 className="h5">
            <span className="text-primary fw-bold h4">
              <span style={{ color: "#4285F4" }}>G</span>
              <span style={{ color: "#DB4437" }}>o</span>
              <span style={{ color: "#F4B400" }}>o</span>
              <span style={{ color: "#4285F4" }}>g</span>
              <span style={{ color: "#0F9D58" }}>l</span>
              <span style={{ color: "#DB4437" }}>e</span>
            </span>{" "}
            Reviews
          </h2>
          <span className="h4 fw-bold">4.8</span>
          <span className="text-warning">★★★★★</span>
          {/* <span className="text-muted">(212)</span> */}
        </div>
        <div className="mt-3 mt-md-0">
          <a href="https://g.co/kgs/VnxwQpQ" target="_blank">
            <button className="btn btn-primary">Review us on Google</button>
          </a>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="position-relative bg-white overflow-hidden">
        <div className="d-flex flex-nowrap justify-content-center align-items-center">
          {/* Left Navigation Button */}
          <button
            className="position-absolute start-0 top-50 translate-middle-y btn btn-light rounded-circle d-none d-md-flex"
            onClick={prevSlide}
          >
            <ChevronLeft size={20} />
          </button>

          {/* Reviews Carousel */}
          <div className="container mx-auto overflow-hidden">
            <div
              className="d-flex flex-nowrap"
              style={{
                transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className="p-3 rounded-4 text-center bg-light mx-2"
                  style={{
                    minWidth: `${100 / slidesToShow}%`,
                  }}
                >
                  <div className="d-flex align-items-center gap-2 p-2">
                    <div className="position-relative">
                      <img
                        className="position-absolute bottom-0 end-0"
                        src={google}
                        alt=""
                        width="20"
                      />
                      <img
                        src={review.image}
                        alt={review.name}
                        className="rounded-circle"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div>
                      <p className="fw-semibold">{review.name}</p>
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <p className="text-warning ps-1" style={{ fontSize: "24px" }}>
                      ★★★★★
                    </p>
                    <p className="text-muted small mt-2">{review.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Navigation Button */}
          <button
            className="position-absolute end-0 top-50 translate-middle-y btn btn-light rounded-circle d-none d-md-flex"
            onClick={nextSlide}
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}
