import React from "react";

const SummerProgram = () => {
  return (
    <>
      <section className="SummerSec">
        <div className="container">
          <div className="row">
            <div className="col-12 headingdiv">
              <h2 className="YellowHeading">
                Details about our Summer Internship Program 2025
              </h2>
              <p>
                Dive Deeper: Unveiling the Details of Your Unforgettable Summer
                Internship Experience – Explore, Learn, and Shape Your Future!
              </p>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-md-4 col-12">
              <div className="SummerDiv first">
                <h3>SUMMER TRAINING START DATES</h3>
                <ul>
                  <li>1st May, 2025</li>
                  <li>15th May, 2025</li>
                  <li>05th June, 2025</li>
                  <li>01th July, 2025</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="SummerDiv second">
                <h3>DURATION OF SUMMER PROGRAM​</h3>
                <ul>
                  <li>4 weeks</li>
                  <li>6 weeks</li>
                  <li>8 weeks</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="SummerDiv third">
                <h3>DELIVERABLES FOR 6 WEEKS SUMMER PROGRAM​</h3>
                <ul>
                  <li>
                    – Industry Recognised Internship (Project) Certificate
                  </li>
                  <li>– Training Certificate from Upflairs Pvt Ltd</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SummerProgram;
