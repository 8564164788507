import React, { useRef, useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Banner from "../Components/Sections/Banner";
import WhyChooseUs from "../Components/Sections/WhyChooseUs";
import JumpCareerCom from "../Components/Sections/JumpCareerCom";
import WhyStudentLove from "../Components/Sections/WhyStudentLove";
import TrustedCollages from "../Components/Sections/TrustedCollages";
import ExploreCourses from "../Components/Sections/ExploreCourses";
import Coureses from "../Components/Sections/Coureses";
import CounterComp from "../Components/Sections/CounterComp";
import Gallery from "../Components/Sections/Gallery";
import SummerProgram from "../Components/Sections/SummerProgram";
import BenefitSummer from "../Components/Sections/BenefitSummer";
import OurTrainer from "../Components/Sections/OurTrainer";
import Testimonial from "../Components/Sections/Testimonial";
import Certificates from "../Components/Sections/Certificates";
import LookOurWork from "../Components/Sections/LookOurWork";
import Footer from "../Components/Footer";
import FAQ from "../Components/Sections/FAQ";
import Popupform from "../Components/Sections/Popupform";
import EnrollForm from "../Components/Sections/EnrollForm";
import RegisterSection from "../Components/Sections/RegisterSection";
import GoogleReviews from "../Components/Sections/GoogleReview";
import Courses from "../Components/Sections/CoursesSlider";

const Home = () => {
  const CourseRef = useRef(null);
  const scrollCourses = () => {
    const navbarheight = 90;
    const courseOffset = CourseRef.current.offsetTop - navbarheight;
    // CourseRef.current.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: courseOffset,
      behavior: "smooth",
    });
  };

  const closeModel = () => {
    setShowPop(false);
  };
  const [showPop, setShowPop] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      // setShowPop(true);
      const paymentSec = document.getElementById("register-section");
      paymentSec.scrollIntoView({ block: "end" });
    }, [5000]);

    return () => clearTimeout(timeOut);
  }, []);

  return (
    <>
      {/* Banner */}
      <Banner scrollCourses={scrollCourses} />

      {/* Courses Section */}
      <Coureses ref={CourseRef} />
      {/* <Courses /> */}

      {/* Why Choose Us */}
      {/* <WhyChooseUs /> */}

      {/* Jump Career */}
      <JumpCareerCom />

      {/* Why Student Love */}
      <WhyStudentLove />

      {/* Trusted Collages */}
      <TrustedCollages />

      {/* Explore Courses Section */}
      <ExploreCourses scrollCourses={scrollCourses} />

      {/* <RegisterSection/> */}
      <RegisterSection />

      {/* Counter Section */}
      <CounterComp />

      {/* Gallery Section */}
      <Gallery />

      {/* Summer Program */}
      <SummerProgram />

      {/* Benefits of Summer Program */}
      <BenefitSummer />

      {/* Our Trainer */}
      <OurTrainer />

      {/* Testimonial Section */}
      <Testimonial />
      <GoogleReviews />

      {/* certicate  */}
      <Certificates />

      {/* FAQ */}
      <FAQ />

      {/* look our Work */}
      <LookOurWork />
      {/* <Popupform />
      {showPop && <EnrollForm closeModel={closeModel} />} */}
    </>
  );
};

export default Home;
